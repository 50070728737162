import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import Layout from '~/components/Common/Layout'
import IconCheck from '~/images/icon/check.svg'
import { Query } from '~/types/graphql-types'
import { STYLE } from '~/utils/constant'
import { getGatsbyImageData, rem } from '~/utils/helper'

const docDetailStyle = css`
  max-width: var(--width-max-content);
  margin-top: calc(var(--height-header) + 72px);
  margin-right: auto;
  margin-left: auto;
  padding-top: 1px;
  padding-right: 24px;
  padding-left: 24px;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    display: grid;
    grid-template-areas: 'description title' 'description form';
    grid-template-columns: 1fr 470px;
    grid-template-rows: auto 1fr;
    margin-top: 80px;
    padding-right: 32px;
    padding-left: 32px;
    gap: 0 32px;
  }

  @media screen and (${STYLE.MEDIA.PC}) {
    padding-right: 0;
    padding-left: 0;
  }

  & > .title {
    margin-right: -24px;
    margin-left: -24px;
    font-size: ${rem(26)};
    font-weight: bold;
    line-height: 1.25;
    text-align: center;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      grid-area: title;
      font-size: ${rem(32)};
    }
  }

  & > .form {
    margin-top: 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      grid-area: form;
    }
  }

  & > .description {
    margin-top: 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      grid-area: description;
      margin-top: 0;
    }
  }

  & > .description > .imagewrapper {
    display: block;
    padding: 32px;
    border-radius: 16px;
    background: var(--color-background-bright);
  }

  & > .description > .imagewrapper > .image {
    display: block;
    width: 100%;
    height: auto;
  }

  & > .description > .points {
    margin-top: 32px;
    padding: 32px;
    border-radius: 16px;
    background: var(--color-background-bluegray50);
  }

  & > .description > .points > .title {
    font-size: ${rem(18)};
    font-weight: bold;
    text-align: center;
  }

  & > .description > .points > .point {
    position: relative;
    margin-top: 16px;
    padding-left: 1.5em;
    font-size: ${rem(14)};
  }

  & > .description > .points > .point > .icon {
    position: absolute;
    top: 0.2em;
    left: 0;
    width: 1em;
    height: auto;
  }

  & > .description > .points > .point > .icon path {
    fill: var(--color-text);
  }

  & > .description > .points > .title + .point {
    margin-top: 32px;
  }
`

export const query = graphql`
  query ($id: String) {
    allFile(filter: { name: { eq: "cms-docs" } }) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          url
          name
          id
        }
      }
    }
    microcmsStgDocs(id: { eq: $id }) {
      id
      point1
      point2
      point3
      stgDocsId
      title
      seo_title
      seo_description
      category {
        name
      }
      form_id
      image_main {
        url
        height
        width
      }
    }
  }
`

const DocDetailPage = ({ data }: { data: Query }) => {
  const pageData = data.microcmsStgDocs
  if (!pageData || !pageData.title) return

  const breadcrumb = [
    {
      label: '資料ダウンロード',
      path: '/resources',
    },
    {
      label: pageData.title,
    },
  ]
  const seo = {
    title: `${pageData.seo_title || pageData.title} | 資料ダウンロード`,
    description: pageData.seo_description || '',
    ogImage: pageData.image_main && pageData.image_main.url ? pageData.image_main.url : '',
  }
  const gatsbyImageData =
    pageData.image_main && pageData.image_main.url ? getGatsbyImageData(data.allFile, pageData.image_main.url) : null
  if (process.env.HUBSPOT_PORTAL_ID && pageData.form_id) {
    useHubspotForm({
      portalId: process.env.HUBSPOT_PORTAL_ID,
      formId: pageData.form_id,
      target: '#download-form',
    })
  }
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={docDetailStyle}>
        <h1 className="title">
          <span>「{pageData.title}」</span>
          <br />
          ダウンロード
        </h1>
        <div className="description">
          <div className="imagewrapper">
            <GatsbyImage className="image" image={gatsbyImageData} alt={pageData.title} />
          </div>
          <dl className="points">
            <dt className="title">こんなことが分かります！</dt>
            {pageData.point1 && (
              <dd className="point">
                <IconCheck className="icon" />
                {pageData.point1}
              </dd>
            )}
            {pageData.point2 && (
              <dd className="point">
                <IconCheck className="icon" />
                {pageData.point2}
              </dd>
            )}
            {pageData.point3 && (
              <dd className="point">
                <IconCheck className="icon" />
                {pageData.point3}
              </dd>
            )}
          </dl>
        </div>
        <div id="download-form" className="form"></div>
      </main>
    </Layout>
  )
}

export default DocDetailPage
